import { db}  from '../services/firebase';

//Misc functions for accessing survey related data (e.g. meta-data or responses)
//(very tuned to how we use Firebase)

//General survey data loader
export const load = async (surveyId) => {
    let surveyObject = null;
    
    //Fetch entire survey node (meta-data and responses)
    try {
      const snapshot = await db.once(`surveys/${surveyId}`);
      if (!snapshot.hasChild('meta/teamId'))
        throw new Error(`No meta-data found for survey ${surveyId}`);
      //Append to id to "raw" object content
      surveyObject = { id : surveyId, ...snapshot.val() };
     } catch (e) {
      const errMsg = "Could not load survey object from database.";
      console.log(errMsg, e);
      throw new Error(errMsg); 
    }
    
    return surveyObject;
};

//Load (and subscribe for) all surveys for a specific team
//Data will be provided to callback function as array with oldest first
//- second argument is an "error" boolean, indicating issues during parsing
//This function returns an "unsubscribe function" that shall be provided to
//cancelAll to end the subscription (at some point)
export const getAll = (teamId, cb) => {
  let unsubscribeFn = null; //default return value
  try {
    if (!cb) //A "better" check would also cover type and signature etc.
      throw new Error("No callback function specified");

    //TODO: check that team (child) exists
    
    const query = db.query("surveys", db.orderByChild("meta/teamId"), db.equalTo(teamId))
    unsubscribeFn = db.onValue(query, snapshot => {
      let surveys = [];
      let anyError = false;
      snapshot.forEach(snap => {
        if (snap.hasChild('meta/teamId')) {
          //Append to id to "raw" object content
          surveys.push({ id: snap.key, ...snap.val() });
        } else {
          //don't throw in this callback (who will catch?)
          console.log(`No meta-data found for survey ${snap.key}`);
          anyError = true;
        }
      });

      //Sort by time? No, just rely on Firebase "default" key order
      //From the docs:
      //"The unique key generated by push() is based on a timestamp,
      // so list items are automatically ordered chronologically."
      cb(surveys, anyError);
    })
  } catch (e) {
    const errMsg = "Could not setup subscription for surveys data.";
    console.log(errMsg, e);
    throw new Error(errMsg);
  }

  return unsubscribeFn;
};

//Cancel subscription for team data (see getAll above)
export const cancelAll = (unsubscribeFn) => {
  try {
    unsubscribeFn();
  } catch(e) {
    const errMsg = "Could not cancel survey data subscription.";
    console.log(errMsg, e);
    throw new Error(errMsg);
  }
};

//Onetime fetch of IDs (only) of all surveys for a specific team
//Result is array if IDs
//(light-weight variant of getAll, without any subscription)
export const fetchAllId = async (teamId) => {
  try {
    const query = db.query("surveys", db.orderByChild("meta/teamId"), db.equalTo(teamId)); 
    const snapshot = await db.once(query);
    let surveyIds = [];
    snapshot.forEach(snap => {
      surveyIds.push(snap.key);
    });
    return surveyIds;
  } catch(e) {
    const errMsg = "Could not fetch survey IDs for team.";
    console.log(errMsg, e);
    throw new Error(errMsg);
  }    
} 

//Derive latest set of questions
//returns (promise of) {id, num}
export const getLatestQuestionSet = async () => {
  try {
    const snapshot = await db.once(
      db.query("question-sets",
        db.orderByChild("createTime"),
        db.limitToLast(1)
      )
    );
    if (snapshot.size !== 1)
      throw new Error("No valid question-set found");

    let retVal = {}
    snapshot.forEach((snap) => {
      retVal = { id: snap.key, num: snap.child("questions").size };
    });

    return retVal;
  } catch (e) {
    const errMsg = "Could not load question set from database.";
    console.log(errMsg, e);
    throw new Error(errMsg);
  }
}

//General survey questions loader (based on question-set ID found in meta-data)
export const loadQuestions = async (surveyObject) => {
  let questions = [];
  let answerOptions = [];
  try {
    //First get the applicable question-set ID for this survey run
    if (!surveyObject.meta.hasOwnProperty('questionsId'))
      throw new Error("No applicable question-set found.");
    const questionsId = surveyObject.meta.questionsId;

    //Then read the actual questions for this question-set ID
    //Firebase only use "objects", i.e. not "arrays". But there should be one key for
    //each index in the intended array (not very robust parsing here).
    const snapshot = await db.once(db.query(`question-sets/${questionsId}`, db.orderByKey()));
    // {questions, answers}
    if (!(snapshot.hasChild("questions") && snapshot.hasChild("answers")))
      throw new Error("Question handle doesn't include questions and answers");
    const numQuestions = snapshot.child("questions").size;
    if (!numQuestions)
      throw new Error(`No questions found for question-set id: ${questionsId}.`);
    const numAnswers = snapshot.child("answers").size;
    if (numAnswers !== 5)
      throw new Error(`Unexpected number of answers found for question-set id: ${questionsId} (${numAnswers})`);
      
    snapshot.child("questions").forEach((snap) => {
      questions.push(snap.val());
    });
    snapshot.child("answers").forEach((snap) => {
      answerOptions.push(snap.val());
    });    
  } catch (e) {
    const errMsg = "Could not load questions from database.";
    console.log(errMsg, e);
    throw new Error(errMsg);
  }
  
  return {questions, answerOptions};
}

//Get the offset [ms] between local and server time (according to Firebase)
export const getServerTimeOffset = async () => {
    let sTimeOffset = null;
    
    try {
      const snapshot = await db.once(".info/serverTimeOffset");
      sTimeOffset = snapshot.val();
    } catch (e) {
      const errMsg = "Could not read time offset from database.";
      console.log(errMsg, e);
      throw new Error(errMsg);
    }

    return sTimeOffset;
};

//Store answers by pushing a new response entry to the database      
export const pushResponse = async (surveyId, answers) => {
  try {
    //In the database, answers are store as an string-version of an array. Why?
    // - Firebase doesn't handled arrays (implicit conversion to Objects)
    //   -> not possible to defined access rules based on "length"
    // - This is a workaround that is based on the fact that we know:
    //   - fixed number of 1-digit numbers (allows for "simple" firebase rule)
    //   - we must "array:ify" the response when we later extract it anyway
    const answersStr = JSON.stringify(answers);
    await db.runTransaction(`surveys/${surveyId}/responses`, prev =>
      (prev ? `${prev},` : " ") + answersStr);
  } catch(e) {
    const errMsg = "Could not write response to database.";
    console.log(errMsg, e);
    throw new Error(errMsg);
  }
}

//Derive closing time
export const getClosingTime = (surveyObject) => {
  try {
    return (surveyObject.meta.hoursOpen * 3600000) + surveyObject.meta.createTime
  } catch (e) {
    const errMsg = "Could not derive closing time for survey";
    console.log(errMsg);
    throw new Error(errMsg);
  }
}

//Check how much time [ms] is left before the survey closes
export const getTimeLeft = (surveyObject, sTimeOffset = 0) => {
    let timeLeft = 0;
    
    try {
      const meta = surveyObject.meta;
      //First check if it has been closed/terminated "manually"
      if (meta.hoursOpen) {
        //Compare current time with survey config
        const closingTime = getClosingTime(surveyObject);
        const now = (new Date()).getTime() + sTimeOffset;
        timeLeft = closingTime - now;
        //console.log("getTimeLeft: closingTime:",closingTime, "now:",now, "timeLeft:", timeLeft);
        timeLeft = (timeLeft < 0) ? 0 : timeLeft; //don't keep track of how long ago it closed
      }
    } catch (e) {
      const errMsg = "Could not derive time left for survey.";
      console.log(errMsg, e);
      throw new Error(errMsg);
    }

    return timeLeft;
};

//Parse the responses string into an array of arrays (of numbers)
//(this is the result of the decision to "simplify" the access rule/validation logic in Firebase)
export const getResponses = (surveyObject) => {
  const meta = surveyObject.meta;
  const lenPerRsp = meta.lenIncrement;
  const maxLen = meta.maxLenAnswers;
  const minLen = meta.minLenAnswers;
  
  if (!(lenPerRsp && maxLen && minLen))
    throw new Error("Survey meta-data missing min/max settings.");
  
  //Don't bother to check too much in advance. Try parsing and do one
  //final check of the end result
  try {
    const ansPerRsp = (lenPerRsp - 2) / 2; //two characters per answer + 2, see createSurvey()
    const min = minLen / lenPerRsp;
    const max = maxLen / lenPerRsp;

    //no responses is a valid (initial) case
    let responses = [];
    if (surveyObject.responses) {
      responses = JSON.parse("[" + surveyObject.responses + "]");
      const allOK = (responses.length <= maxLen) &&
        responses.reduce((rOK, r) => (
          rOK && r.length === ansPerRsp &&
          r.reduce((aOK, a) => (
            aOK && Number.isInteger(a) && a >= 0 && a <=4
          ),true)
        ), true);
      
      if (!allOK) {
        const errMsg = "Failed to parse/validate responses."; 
        console.log(errMsg, responses);
        throw new Error(errMsg);
      }
    }
    return {responses, min, max};
  } catch(e) {
    const errMsg = "Survey meta-data for min/max settings corrupted.";
    console.log(errMsg, e);
    throw new Error(errMsg);
  }
}

//Is the survey still open (can we post new answers?).
//Two variants;
//- <no-suffix> (will analyze an existing object structure, i.e. already fetched data) 
//- "ById" ("async" variant; will fetch fresh data from Firebase)
export const isOpen = (surveyObject) => {
  try {
    const timeLeft = getTimeLeft(surveyObject);
    const respHandle = getResponses(surveyObject);

    return (timeLeft > 0) && 
      (respHandle.responses.length < respHandle.max);
  } catch(e) {
    const errMsg = "Could not determine open-status for survey.";
    console.log(errMsg, e);
    throw new Error(errMsg);
  }
}

export const isOpenById = async (surveyId) => {
  try {
    const surveyObject = await load(surveyId);
    return isOpen(surveyObject);
  } catch(e) {
    const errMsg = `Could not determine open-status for survey id ${surveyId}.`;
    console.log(errMsg, e);
    throw new Error(errMsg);
  }
}

//Helper for determining the completion level of a survey based on responses etc.
export const CompLev = {
  CANCELED: 'canceled',
  TOO_FEW : 'too few responders',
  SOME    : 'some responders',
  ALL     : 'all responders'  
};
export const getCompLev = (surveyObj, respHandle = null) => {
  let level = CompLev.CANCELED;  
  
  try {
    if (!respHandle) {
      respHandle = getResponses(surveyObj);
    }

    if (surveyObj.meta.hoursOpen) {
      if (respHandle.responses.length < respHandle.min) {
        level = CompLev.TOO_FEW;
      } else if (respHandle.responses.length === respHandle.max) {
        level = CompLev.ALL;
      } else {
        level = CompLev.SOME;
      }
    } else {
      if (respHandle.responses.length === respHandle.max) {
        //Explicitly "completed"
        level = CompLev.ALL;
      } else {
        //Explicitly cancelled
        level = CompLev.CANCELED;
      }
    }
  } catch(e) {
    const errMsg = `Could not determine completion level`;
    console.log(errMsg, e);
    //TBD: better to throw here?
    //throw new Error(errMsg);
    level = CompLev.CANCELED;
  }
  return level;
}

//Derive (/refine) meta-status for a survey object
//NOTE: returns an object { meta, respHandle }
export const getMeta = (surveyObj, respHandle = null) => {
  try {
    if (!respHandle) {
      respHandle = getResponses(surveyObj);
    }
    
    //Collect the "meta status"
    const meta = {
      id: surveyObj.id,
      createTime: surveyObj.meta.createTime,
      closingTime: getClosingTime(surveyObj), 
      compLev: getCompLev(surveyObj, respHandle),
      ongoing: isOpen(surveyObj),
      numResponders: respHandle.responses.length,
      maxNumResponders: respHandle.max
    }

    return { meta, respHandle };

  } catch (e) {
    const errMsg = `Could not derive survey meta data`;
    console.log(errMsg, e);
    throw new Error(errMsg);    
  }
}


//Create a new survey based on the latest set of questions
//returns (promise of) surveyId
export const createSurvey = async (teamId, minAnswers, maxAnswers, hoursOpen) => {
  try {
    const questions = await getLatestQuestionSet();
    //To make validation rules simpler the various "len" entires are in #characters (when stored)
    //E.g. for 3 questions the answer may be ",[1,4,2]" (i.e. length 8)
    const lenIncrement = questions.num * 2 + 2;
    const minLenAnswers = minAnswers * lenIncrement;
    const maxLenAnswers = maxAnswers * lenIncrement;
    const result = await db.push(`surveys`, {
      meta: {
        ...{ teamId, minLenAnswers, maxLenAnswers, lenIncrement, hoursOpen },
        createTime: { ".sv": "timestamp" },
        questionsId: questions.id,
      },
    });
    
    return result.key;
  } catch (e) {
    const errMsg = "Could not create survey. " + e.message;
    console.log(errMsg, e);
    throw new Error(errMsg);
  }
}


//Delete (purge) an existing survey from the database
//NOTE: there is no "undo" for this
export const deleteSurvey = async (surveyId) => {
  try {
    await db.remove(`surveys/${surveyId}`);
  } catch (e) {
    const errMsg = "Could not delete survey. " + e.message;
    console.log(errMsg, e);
    throw new Error(errMsg);
  }
}

//Update the hoursOpen attribute (disregard previous settings)
export const setClosingTime = async (surveyId, createTime, hoursFromNow) => {
  try {
    const offset = await getServerTimeOffset();
    const closingTime = (new Date()).getTime() + offset + (hoursFromNow * 3600000);
    const newHoursOpen = (closingTime - createTime) / 3600000;
    await db.set(`surveys/${surveyId}/meta/hoursOpen`, newHoursOpen);
  } catch (e) {
    const errMsg = "Could not update survey. " + e.message;
    console.log(errMsg, e);
    throw new Error(errMsg);
  }
}

//Allow for more responders by increasing the maxLenAnswers attribute
export const addResponders = async (surveyId, numAdditional) => {
  //TODO/TBD: improve access rules for entire meta sub-object to allow for
  //a single "transaction" on it (avoid separate readout of lenIncrement)
  try {
    //First get the lenIncrement
    const snapshot = await db.once(`surveys/${surveyId}/meta/lenIncrement`);
    const lenIncrement = snapshot.val();

    //Now use a transaction to ensure atomic update
    await db.runTransaction(`surveys/${surveyId}/meta/maxLenAnswers`, prev => 
      prev + (lenIncrement * numAdditional));
  } catch (e) {
    const errMsg = "Could not update survey. " + e.message;
    console.log(errMsg, e);
    throw new Error(errMsg);
  }
}

//Indicate survey as "completed" by closing it immediately and adjusting maxLenAnswers attribute
export const markCompleted = async (surveyId) => {
  //TODO/TBD: improve access rules for entire meta sub-object to allow for
  //a single "transaction" on it (avoid sequence of write/read/write)
  try {
    //First close the survey to avoid additional updates
    await db.set(`surveys/${surveyId}/meta/hoursOpen`, 0);
    
    //Get the current number of responders
    const surveyObject = await load(surveyId);
    const currentLen = surveyObject.responses.length;
    
    //Then update the expected maximum according the current value
    if (currentLen >= surveyObject.meta.minLenAnswers) {
      await db.set(`surveys/${surveyId}/meta/maxLenAnswers`, currentLen);
    } else {
      //Doesn't make sense to have a "max" set to less than "min". The completed survey
      //will not be "good enough" for deriving any result anyway so just let it be.
      //I.e. this will be a kind of "discard" operation rather than "complete".
    }

  } catch (e) {
    const errMsg = "Could not update survey. " + e.message;
    console.log(errMsg, e);
    throw new Error(errMsg);
  }
}